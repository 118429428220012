"use client";

import { FC, HTMLAttributes, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import Head from '@/components/shared/Head';
import Image from '@/components/shared/Image';
import HeadingText from '@/components/shared/HeadingText';

interface ReviewProps extends HTMLAttributes<HTMLDivElement> {
    type?: "single" | "multiple";
    title?: string;
    titleVariant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    reviewId?: string;
    reviews: Array<{
        id: string;
        name: string;
        image: string;
        function: string;
        review: string;
    }>;
    className?: string;
}

const Review: FC<ReviewProps> = ({ type = "multiple", titleVariant = "h6", title = "", reviewId, reviews, className = '', children }: ReviewProps) => {
    let randReview = reviews[Math.floor(Math.random() * reviews.length)];

    if (type === "single" && reviewId) {
        randReview = reviews.find((review) => review.id === reviewId)!;
    }

    const getReviewSchema = (reviews: Array<{ id: string; name: string; review: string; }>) => {
        let returnString = '';

        reviews.map((review, index) => {
            returnString += `
                {
                    "@type": "Review",
                    "author": {
                        "@type": "Person",
                        "name": "${review.name}"
                    },
                    "reviewBody": "${review.review}"
                }${index < reviews.length - 1 ? ',' : ''}
            `;
        });
        return returnString;
    };

    return (
        <>
            {type === "single" && (
                <div className={`w-1/2 flex gap-5 items-center ml-auto animate-fade-in ${className}`}>
                    <Image
                        width={85}
                        height={85}
                        loading="lazy"
                        src={randReview.image}
                        className={'max-w-85px aspect-square object-cover rounded-full'}
                        alt="Profile"
                    />
                    <div className={'flex flex-col'}>
                        <Typography className={'max-w-900px font-bold leading-5 normal-case text-xl'} variant={titleVariant}>
                            {randReview.name}
                        </Typography>
                        <Typography className={'max-w-900px mb-2 leading-5 normal-case'} variant='body2'>
                            {randReview.function}
                        </Typography>
                        <Typography className={'max-w-900px font-medium leading-5 normal-case'} variant='body1'>
                            <span className={'text-2xl leading-5 normal-case'}>{'" '}</span>
                            {randReview.review}
                            <span className={'text-2xl leading-5 normal-case'}>{' "'}</span>
                        </Typography>
                    </div>
                </div>
            )}

            {type === "multiple" &&
                <Container>
                    <Head tag="script" type='application/ld+json' content={`
                        {
                            "@context": "https://schema.org",
                            "@type": "LocalBusiness",
                            "review": [
                                ${getReviewSchema(reviews)}
                            ]
                        }
                    `} />

                    {children && children} 
                    <HeadingText variant="h4" className="text-center mb-10">{title}</HeadingText>
                    <div className={'grid grid-cols-1 md:grid-cols-2 gap-16'}>
                        {reviews.map((review, index) => (
                            <div className={`w-full flex gap-5 items-center ${className}`} key={index}>
                                <Image
                                    width={85}
                                    height={85}
                                    loading="lazy"
                                    src={review.image}
                                    className={'max-w-85px aspect-square object-cover rounded-full'}
                                    alt="Profile"
                                />
                                <div className={'flex flex-col'}>
                                    <Typography className={`max-w-900px font-bold leading-5 normal-case text-xl`} variant='body1'>
                                        {review.name}
                                    </Typography>
                                    <Typography className={'max-w-900px mb-2 leading-5 normal-case'} variant='body2'>
                                        {review.function}
                                    </Typography>
                                    <Typography className={'max-w-900px font-medium leading-5 normal-case'} variant='body1'>
                                        <span className={'text-2xl leading-5 normal-case'}>{'" '}</span>
                                        {review.review}
                                        <span className={'text-2xl leading-5 normal-case'}>{' "'}</span>
                                    </Typography>
                                </div>
                            </div>
                        ))}
                    </div>
                </Container>
            }
        </>
    );
};

export default Review;