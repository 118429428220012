import { ExtendedPathnames, locales, route } from '@/lib/navigationFunctions';
import React, { useEffect, useRef, useState } from 'react';

import { Container, Typography } from '@mui/material';
import { DefaultPageProps } from '@/lib/types/shared/page.types';
import { MoveFadeInAnimation } from '@/lib/hooks/use-animations';
import { useTranslations } from 'next-intl';
import { capitalize } from '@/lib/utils';
import HeadingText from '@/components/shared/HeadingText';

type Props = {
    title?: boolean;
    useCombinedName?: boolean;
    typeSingle?: string;
    typePlural?: string;
    content?: boolean;
    letterLine?: boolean;
    translate?: boolean;
    links?: ExtendedPathnames<typeof locales>;
    multipleLinks?: {
        [key: string]: ExtendedPathnames<typeof locales>;
    };
    linkSize?: 'small' | 'normal' | 'large';
    lg?: string;
} & DefaultPageProps;

type Groups = {
    [key: string]: any[];
} | null;

export default function LinkList({ title = false, useCombinedName = false, typeSingle = '', typePlural = '', content = false, letterLine = false, translate = false, links, multipleLinks, linkSize = 'normal', lg, params }: Props) {
    // console.log(links);
    

    const t = useTranslations("shared.components.link_list");

    const [groups, setgroups] = useState<Groups>(null);
    const [multipleGroups, setMultipleGroups] = useState<{
        [key: string]: Groups;
    } | null>(null);
    const [letterLineActive, setLetterLineActive] = useState<boolean>(false);
    const [classDisabled, setClassDisabled] = useState<boolean>(false);

    const [letterLinesActive, setLetterLinesActive] = useState<{
        [key: string]: boolean;
    } | null>(null);
    const [classesDisabled, setClassesDisabled] = useState<{
        [key: string]: boolean;
    } | null>(null);

    const letterLineRef = useRef<HTMLDivElement>(null);
    const letterLineRefs = useRef<HTMLDivElement[]>([]);

    let disabled: boolean = false;
    let disabledLines: {
        [key: string]: boolean;
    } = {};

    const handleScroll = (e: any) => {
        if (letterLineRef.current === null && (letterLineRefs.current === null || letterLineRefs.current.length === 0)) return;
        
        let prevScroll = 0;
        let currentScroll = window.pageYOffset;

        if (letterLineRef.current !== null) {
            const letterLine = letterLineRef.current;
            const letterLineParent = document.getElementById('letterLineParent');
            const letterLineDiv = document.getElementById('letterLine');
            const lettersWrapper = document.getElementById('lettersWrapper');

            try {
                // If letterLineDiv is above the bottom of the parent AND the user is scrolling up, enable the letterLine
                if (letterLineDiv!.getBoundingClientRect().bottom < letterLineParent!.getBoundingClientRect().bottom && prevScroll < currentScroll) {
                    disabled = false;
                    setClassDisabled(false);
                    setLetterLineActive(true);
                }

                if (disabled) return;

                // If letterLineDiv is below the bottom of the parent, disable the letterLine
                if (letterLineDiv!.getBoundingClientRect().bottom >= letterLineParent!.getBoundingClientRect().bottom) {
                    disabled = true;
                    setClassDisabled(true);
                    setLetterLineActive(false);

                    lettersWrapper!.style.paddingTop = '0';

                    return;
                }

                if (letterLine.getBoundingClientRect().top <= 144) {
                    setLetterLineActive(true);

                    if (letterLineParent) {
                        letterLineDiv!.style.width = `${letterLineParent.clientWidth + 2}px`;
                    }

                    lettersWrapper!.style.paddingTop = `${letterLineDiv!.clientHeight}px`;
                    lettersWrapper!.childNodes.forEach((child: any) => {
                        if (child.nodeName === 'DIV') {
                            child.style.scrollMarginTop = `${letterLineDiv!.clientHeight + (288 / 2)}px`;
                        }
                    });
                } else {
                    setLetterLineActive(false);

                    lettersWrapper!.style.paddingTop = '0';
                }
            } catch (e) {
                console.error(e);
            }
        } else if (letterLineRefs.current !== null && letterLineRefs.current.length > 0) {
            let letterLine = null;
            const letterLineParent = document.getElementById('letterLineParent');
            let letterLineDiv = null;
            let lettersWrapper = null;

            letterLineRefs.current.forEach((el) => {
                if (el === null) return;

                letterLine = el;
                letterLineDiv = document.getElementById(el.dataset.letterLineId!)
                lettersWrapper = document.getElementById(el.dataset.lettersWrapperId!);

                try {
                    if (letterLineDiv!.getBoundingClientRect().bottom < letterLineParent!.getBoundingClientRect().bottom && prevScroll < currentScroll) {
                        disabledLines[el.dataset.letterLineId!] = false;
                        setClassesDisabled((prev) => {
                            return {
                                ...prev,
                                [el.dataset.letterLineId!]: false
                            }
                        });
                        setLetterLinesActive((prev) => {
                            return {
                                ...prev,
                                [el.dataset.letterLineId!]: true
                            }
                        });
                    }

                    if (disabledLines[el.dataset.letterLineId!] === true) return;

                    if (letterLineDiv!.getBoundingClientRect().bottom >= letterLineParent!.getBoundingClientRect().bottom) {
                        disabledLines[el.dataset.letterLineId!] = true;
                        setClassesDisabled((prev) => {
                            return {
                                ...prev,
                                [el.dataset.letterLineId!]: true
                            }
                        });
                        setLetterLinesActive((prev) => {
                            return {
                                ...prev,
                                [el.dataset.letterLineId!]: false
                            }
                        });

                        lettersWrapper!.style.paddingTop = '0';

                        return;
                    }

                    if (letterLine.getBoundingClientRect().top <= 144) {
                        setLetterLinesActive((prev) => {
                            return {
                                ...prev,
                                [el.dataset.letterLineId!]: true
                            }
                        });

                        if (letterLineParent) {
                            letterLineDiv!.style.width = `${letterLineParent.clientWidth + 2}px`;
                        }

                        lettersWrapper!.style.paddingTop = `${letterLineDiv!.clientHeight}px`;
                        lettersWrapper!.childNodes.forEach((child: any) => {
                            if (child.nodeName === 'DIV') {
                                child.style.scrollMarginTop = `${letterLineDiv!.clientHeight + (288 / 2)}px`;
                            }
                        });
                    } else {
                        setLetterLinesActive((prev) => {
                            return {
                                ...prev,
                                [el.dataset.letterLineId!]: false
                            }
                        });

                        lettersWrapper!.style.paddingTop = '0';
                    }
                } catch (e) {
                    console.error(e);
                }
            });
        }

        prevScroll = currentScroll <= 0 ? 0 : currentScroll;
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (links) {
            const groupsObj = Object.keys(links).reduce((acc: any, key) => {
                let link = links[key];

                let nameToUse = link.name;

                if (translate === true) {
                    link = {
                        ...link,
                        name: t(nameToUse)
                    };
                    // Type '{ name: string; en: string; nl: string; showInMenu?: boolean | undefined; includeInMenu?: boolean | undefined; parentKey?: string | undefined; breadcrumbParentKey?: string | undefined; } | { ...; }' is not assignable to type '({ en: string; nl: string; } & { name: string; showInMenu?: boolean | undefined; includeInMenu?: boolean | undefined; parentKey?: string | undefined; breadcrumbParentKey?: string | undefined; }) | ExtendedPathnames<...>'.
                }
    
                // let split = nameToUse.match(/([A-Z][a-z]+)|([A-Z]{1})/g);
                let split = null;
                if (typeof nameToUse === 'string') {
                    split = nameToUse.match(/([A-Z][a-z]+)|([A-Z])/g);
                }
    
                if (split === null) {
                    if (typeof nameToUse === 'string') {
                        split = nameToUse.match(/\d+|\D+/g);
                    }
                }
    
                if (isNaN(parseInt(split![0]))) {
                    if (!acc[split![0][0]]) {
                        acc[split![0][0]] = [];
                    }
    
                    acc[split![0][0]].push(link);
                } else {
                    if (!acc["#"]) {
                        acc["#"] = [];
                    }
    
                    acc["#"].push(link);
                }
    
                return acc;
            }, {});
    
            const sortedKeys = Object.keys(groupsObj).sort();
            const sortedGroupsObj: Groups = {};
    
            sortedKeys.forEach((key) => {
                sortedGroupsObj[key] = groupsObj[key].sort((a: any, b: any) => {
                    if (a.name < b.name) {
                        return -1;
                    }
    
                    if (a.name > b.name) {
                        return 1;
                    }
    
                    return 0;
                });
            });
    
            setgroups(sortedGroupsObj);
        } else if (multipleLinks) {
            Object.entries(multipleLinks).forEach(async ([key, value]) => {
                const groupsObj = Object.keys(value).reduce((acc: any, key) => {
                    let link = value[key];

                    let nameToUse = link.name;

                    if (translate === true) {
                        link = {
                            ...link,
                            name: t(nameToUse)
                        };
                    }
        
                    let split = null;
                    if (typeof nameToUse === 'string') {
                        split = nameToUse.match(/([A-Z][a-z]+)|([A-Z])/g);
                    }
        
                    if (split === null) {
                        if (typeof nameToUse === 'string') {
                            split = nameToUse.match(/\d+|\D+/g);
                        }
                    }
        
                    if (isNaN(parseInt(split![0]))) {
                        if (!acc[split![0][0]]) {
                            acc[split![0][0]] = [];
                        }
        
                        acc[split![0][0]].push(link);
                    } else {
                        if (!acc["#"]) {
                            acc["#"] = [];
                        }
        
                        acc["#"].push(link);
                    }
        
                    return acc;
                }, {});
        
                const sortedKeys = Object.keys(groupsObj).sort();
                const sortedGroupsObj: Groups = {};
        
                sortedKeys.forEach(async (key) => {
                    sortedGroupsObj[key] = groupsObj[key].sort((a: any, b: any) => {
                        if (a.name < b.name) {
                            return -1;
                        }
        
                        if (a.name > b.name) {
                            return 1;
                        }
        
                        return 0;
                    });
                });
        
                setMultipleGroups((prev) => ({
                    ...prev,
                    [key]: sortedGroupsObj
                }));
            });

        }
    }, []);

    useEffect(() => {
        if (multipleGroups !== null) {
            letterLineRefs.current = letterLineRefs.current.slice(0, Object.keys(multipleGroups).length);
        }
    }, [multipleGroups]);

    return (
        <div className='flex flex-col gap-4' id='letterLineParent'>
            {title &&
                <MoveFadeInAnimation direction="right" distance="xl" amount={0.5}>
                    <HeadingText variant="h3" className='mb-4 max-w-screen-md'>
                        {t("title")}
                    </HeadingText>
                </MoveFadeInAnimation>
            }

            {content &&
                <MoveFadeInAnimation delay={200} direction="right" distance="xl" amount={0.5}>
                    <Typography className='text-start normal-case max-w-screen-md' variant='body1'>
                        {t("content", {
                            type: typeSingle ?? '',
                            types: typePlural ?? ''
                        })}
                    </Typography>
                </MoveFadeInAnimation>
            }

            {groups !== null && (
                letterLine && (
                    <>
                        <div ref={letterLineRef} />

                        <div id='letterLine' className={`${letterLineActive ? 'fixed top-36 left-1/2 -translate-x-1/2 bg-primary-gray-950' : classDisabled == true ? 'fixed top-36 left-1/2 -translate-x-1/2 opacity-0' : ''} max-w-1202px`}>
                            <hr />
                            
                            <div className='flex flex-wrap items-center gap-2'>
                                {groups && Object.keys(groups).map((key, index) => 
                                    <a key={index} href={`#${key}`} className='w-fit group p-3 relative cursor-pointer'>
                                        <div className='absolute inset-0 -z-1 bg-transparent group-hover:bg-white opacity-10 transition-colors duration-300' />

                                        <HeadingText variant="h4">
                                            {key}
                                        </HeadingText>
                                    </a>
                                )}
                            </div>

                            <hr />
                        </div>
                    </>
                )
            )}

            {groups !== null && (
                <div id='lettersWrapper' className="flex flex-col gap-4">
                    {groups && Object.keys(groups).map((key, index) => {
                        const group = groups[key];

                        return (
                            <div key={index} id={key}>
                                <HeadingText variant="h4">
                                    {key}
                                </HeadingText>

                                <div className='flex flex-wrap'>
                                    {group.map((link: any) => {
                                        return (
                                            <div key={link.en} className={`${group.length > 8 ? `w-1/2 md:w-1/3 ${lg ? lg : 'lg:w-1/4}'}` : 'w-full'}`}>
                                                <a href={route(link.en, params.locale, '', true)} className={`text-white-accent-50 ${linkSize === 'small' ? 'text-sm' : linkSize === 'large' ? 'text-xl' : 'text-base'}`}>
                                                    {useCombinedName ? link.combinedName : link.name}
                                                </a>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}




            {multipleGroups !== null && (
                Object.entries(multipleGroups).map(([key, groups], index) => (
                    <div key={index} className='my-3'>
                        <HeadingText variant="h4" className='max-w-screen-md'>
                            {capitalize(t(key, {
                                type: typeSingle ?? '',
                                types: typePlural ?? ''
                            }))}
                        </HeadingText>

                        {groups !== null && (
                            letterLine && (
                                <>
                                    <div data-letter-line-id={`letterLine${key}`} data-letters-wrapper-id={`lettersWrapper${key}`} ref={(el) => letterLineRefs.current.push(el!)} />

                                    <div id={`letterLine${key}`} className={`${letterLinesActive !== null && letterLinesActive![`letterLine${key}`] ? 'fixed top-36 left-1/2 -translate-x-1/2 bg-primary-gray-950' : classesDisabled !== null && classesDisabled![`letterLine${key}`] == true ? 'fixed top-36 left-1/2 -translate-x-1/2 opacity-0' : ''} max-w-1202px`}>
                                        <hr />
                                        
                                        {/* Not scrollable */}
                                        {/* <div className='flex flex-wrap items-center gap-2'> */}
                                        {/* Scrollable */}
                                        <div className='flex items-center gap-2 overflow-x-auto no-scrollbar'>
                                            {groups && Object.keys(groups).map((letterKey, index) => 
                                                <a key={index} href={`#${key}${letterKey}`} className='w-fit group p-3 relative cursor-pointer'>
                                                    <div className='absolute inset-0 -z-1 bg-transparent group-hover:bg-white opacity-10 transition-colors duration-300' />

                                                    <HeadingText variant="h4">
                                                        {letterKey}
                                                    </HeadingText>
                                                </a>
                                            )}
                                        </div>

                                        <hr />
                                    </div>
                                </>
                            )
                        )}

                        <div id={`lettersWrapper${key}`} className="flex flex-col gap-4">
                            {groups && Object.keys(groups).map((letterKey, index) => {
                                const group = groups[letterKey];

                                return (
                                    <div key={index} id={`${key}${letterKey}`}>
                                        <HeadingText variant="h4">
                                            {letterKey}
                                        </HeadingText>

                                        <div className='flex flex-wrap'>
                                            {group.map((link: any) => {
                                                return (
                                                    <div key={link.en} className={`${group.length > 8 ? `w-1/2 md:w-1/3 ${lg ? lg : 'lg:w-1/4}'}` : 'w-full'}`}>
                                                        <a href={route(link.en, params.locale)} className={`text-white-accent-50 ${linkSize === 'small' ? 'text-sm' : linkSize === 'large' ? 'text-xl' : 'text-base'}`}>{link.name}</a>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ))
            )}
        </div>
    )
}
